export const lightColors = {
  Neutral: "#ffffff",
  text: "#333333",
  colorBackgroundDisabled: "#091e4208",
  colorBackgroundInput: "#ffffff",
  colorBackgroundInputHovered: "#F7F8F9",
  colorBackgroundInputPressed: "#ffffff",
  colorBackgroundAccentBlueBolder: "#0c66e4",
  colorBackgroundAccentBlueBolderHovered: "#0055cc",
  colorBackgroundAccentBlueBolderPressed: "#09326c",
  colorBackgroundAccentBlueSubtle: "#579dff",
  colorBackgroundAccentBlueSubtleHovered: "#85b8ff",
  colorBackgroundAccentBlueSubtlePressed: "#cce0ff",
  colorBackgroundAccentBlueSubtler: "#ebf3ff",
  colorBackgroundAccentBlueSubtlerHovered: "#cce0ff",
  colorBackgroundAccentBlueSubtlerPressed: "#85b8ff",
  colorBackgroundAccentBlueSubtlest: "#ebf3ff",
  colorBackgroundAccentBlueSubtlestHovered: "#cce0ff",
  colorBackgroundAccentBlueSubtlestPressed: "#85b8ff",
  colorBackgroundAccentGrayBolder: "#626f86",
  colorBackgroundAccentGrayBolderHovered: "#44546f",
  colorBackgroundAccentGrayBolderPressed: "#2c3e5d",
  colorBackgroundAccentGraySubtle: "#8590a2",
  colorBackgroundAccentGraySubtleHovered: "#b3b9c4",
  colorBackgroundAccentGraySubtlePressed: "#dcdfe4",
  colorBackgroundAccentGraySubtler: "#dcdfe4",
  colorBackgroundAccentGraySubtlerHovered: "#b3b9c4",
  colorBackgroundAccentGraySubtlerPressed: "#8590a2",
  colorBackgroundAccentGraySubtlest: "#f1f2f4",
  colorBackgroundAccentGraySubtlestHovered: "#dcdfe4",
  colorBackgroundAccentGraySubtlestPressed: "#b3b9c4",
  colorBackgroundAccentGreenBolder: "#1f845a",
  colorBackgroundAccentGreenBolderHovered: "#216e4e",
  colorBackgroundAccentGreenBolderPressed: "#164b35",
  colorBackgroundAccentGreenSubtle: "#4bce97",
  colorBackgroundAccentGreenSubtleHovered: "#7ee2b8",
  colorBackgroundAccentGreenSubtlePressed: "#baf3db",
  colorBackgroundAccentGreenSubtler: "#dffcf0",
  colorBackgroundAccentGreenSubtlerHovered: "#baf3db",
  colorBackgroundAccentGreenSubtlerPressed: "#7ee2b8",
  colorBackgroundAccentGreenSubtlest: "#dffcf0",
  colorBackgroundAccentGreenSubtlestHovered: "#baf3db",
  colorBackgroundAccentGreenSubtlestPressed: "#7ee2b8",
  colorBackgroundAccentLimeBolder: "#5b7f24",
  colorBackgroundAccentLimeBolderHovered: "#4c6b1f",
  colorBackgroundAccentLimeBolderPressed: "#37471f",
  colorBackgroundAccentLimeSubtle: "#94c748",
  colorBackgroundAccentLimeSubtleHovered: "#b3df72",
  colorBackgroundAccentLimeSubtlePressed: "#d3f1a7",
  colorBackgroundAccentLimeSubtler: "#eefbda",
  colorBackgroundAccentLimeSubtlerHovered: "#d3f1a7",
  colorBackgroundAccentLimeSubtlerPressed: "#b3df72",
  colorBackgroundAccentLimeSubtlest: "#eefbda",
  colorBackgroundAccentLimeSubtlestHovered: "#d3f1a7",
  colorBackgroundAccentLimeSubtlestPressed: "#b3df72",
  colorBackgroundAccentMagentaBolder: "#ae4787",
  colorBackgroundAccentMagentaBolderHovered: "#943d73",
  colorBackgroundAccentMagentaBolderPressed: "#50253f",
  colorBackgroundAccentMagentaSubtle: "#e774bb",
  colorBackgroundAccentMagentaSubtleHovered: "#f797d2",
  colorBackgroundAccentMagentaSubtlePressed: "#fdd0ec",
  colorBackgroundAccentMagentaSubtler: "#ffecf8",
  colorBackgroundAccentMagentaSubtlerHovered: "#fdd0ec",
  colorBackgroundAccentMagentaSubtlerPressed: "#f797d2",
  colorBackgroundAccentMagentaSubtlest: "#ffecf8",
  colorBackgroundAccentMagentaSubtlestHovered: "#fdd0ec",
  colorBackgroundAccentMagentaSubtlestPressed: "#f797d2",
  colorBackgroundAccentOrangeBolder: "#b65c02",
  colorBackgroundAccentOrangeBolderHovered: "#974f0c",
  colorBackgroundAccentOrangeBolderPressed: "#5f3811",
  colorBackgroundAccentOrangeSubtle: "#faa53d",
  colorBackgroundAccentOrangeSubtleHovered: "#fec57b",
  colorBackgroundAccentOrangeSubtlePressed: "#ffe2bd",
  colorBackgroundAccentOrangeSubtler: "#fff4e5",
  colorBackgroundAccentOrangeSubtlerHovered: "#ffe2bd",
  colorBackgroundAccentOrangeSubtlerPressed: "#fec57b",
  colorBackgroundAccentOrangeSubtlest: "#fff4e5",
  colorBackgroundAccentOrangeSubtlestHovered: "#ffe2bd",
  colorBackgroundAccentOrangeSubtlestPressed: "#fec57b",
  colorBackgroundAccentPurpleBolder: "#6e5dc6",
  colorBackgroundAccentPurpleBolderHovered: "#5e4db2",
  colorBackgroundAccentPurpleBolderPressed: "#352c63",
  colorBackgroundAccentPurpleSubtle: "#9f8fef",
  colorBackgroundAccentPurpleSubtleHovered: "#b8acf6",
  colorBackgroundAccentPurpleSubtlePressed: "#dfd8fd",
  colorBackgroundAccentPurpleSubtler: "#f3f0ff",
  colorBackgroundAccentPurpleSubtlerHovered: "#dfd8fd",
  colorBackgroundAccentPurpleSubtlerPressed: "#b8acf6",
  colorBackgroundAccentPurpleSubtlest: "#f3f0ff",
  colorBackgroundAccentPurpleSubtlestHovered: "#dfd8fd",
  colorBackgroundAccentPurpleSubtlestPressed: "#b8acf6",
  colorBackgroundAccentRedBolder: "#ca3521",
  colorBackgroundAccentRedBolderHovered: "#ae2a19",
  colorBackgroundAccentRedBolderPressed: "#601e16",
  colorBackgroundAccentRedSubtle: "#f87462",
  colorBackgroundAccentRedSubtleHovered: "#ff9c8f",
  colorBackgroundAccentRedSubtlePressed: "#ffd2cc",
  colorBackgroundAccentRedSubtler: "#ffedeb",
  colorBackgroundAccentRedSubtlerHovered: "#ffd2cc",
  colorBackgroundAccentRedSubtlerPressed: "#ff9c8f",
  colorBackgroundAccentRedSubtlest: "#ffedeb",
  colorBackgroundAccentRedSubtlestHovered: "#ffd2cc",
  colorBackgroundAccentRedSubtlestPressed: "#ff9c8f",
  colorBackgroundAccentTealBolder: "#1d7f8c",
  colorBackgroundAccentTealBolderHovered: "#206b74",
  colorBackgroundAccentTealBolderPressed: "#1d474c",
  colorBackgroundAccentTealSubtle: "#60c6d2",
  colorBackgroundAccentTealSubtleHovered: "#8bdbe5",
  colorBackgroundAccentTealSubtlePressed: "#c1f0f5",
  colorBackgroundAccentTealSubtler: "#e3fafc",
  colorBackgroundAccentTealSubtlerHovered: "#c1f0f5",
  colorBackgroundAccentTealSubtlerPressed: "#8bdbe5",
  colorBackgroundAccentTealSubtlest: "#e3fafc",
  colorBackgroundAccentTealSubtlestHovered: "#c1f0f5",
  colorBackgroundAccentTealSubtlestPressed: "#8bdbe5",
  colorBackgroundAccentYellowBolder: "#E50045",
  colorBackgroundAccentYellowBolderHovered: "#d67d00",
  colorBackgroundAccentYellowBolderPressed: "#6f4100",
  colorBackgroundAccentYellowSubtle: "#E50045",
  colorBackgroundAccentYellowSubtleHovered: "#ffcf51",
  colorBackgroundAccentYellowSubtlePressed: "#ffe399",
  colorBackgroundAccentYellowSubtler: "#fff3d6",
  colorBackgroundAccentYellowSubtlerHovered: "#ffe399",
  colorBackgroundAccentYellowSubtlerPressed: "#ffcf51",
  colorBackgroundAccentYellowSubtlest: "#fff3d6",
  colorBackgroundAccentYellowSubtlestHovered: "#ffe399",
  colorBackgroundAccentYellowSubtlestPressed: "#ffcf51",
  colorBackgroundBrandPrimaryBold: "#E50045",
  colorBackgroundBrandPrimaryBoldHovered: "#FFAC05",
  colorBackgroundBrandPrimaryBoldPressed: "#E50045",
  colorBackgroundBrandPrimaryBolder: "#082145",
  colorBackgroundBrandPrimaryBolderHovered: "#09326c",
  colorBackgroundBrandPrimaryBolderPressed: "#0055cc",
  colorBackgroundBrandPrimarySubtlest: "#ebf3ff",
  colorBackgroundBrandPrimarySubtlestHovered: "#cce0ff",
  colorBackgroundBrandPrimarySubtlestPressed: "#85b8ff",
  colorBackgroundBrandSecondaryBold: "#6e5dc6",
  colorBackgroundBrandSecondaryBoldHovered: "#5e4db2",
  colorBackgroundBrandSecondaryBoldPressed: "#352c63",
  colorBackgroundBrandSecondaryBolder: "#231c3f",
  colorBackgroundBrandSecondaryBolderHovered: "#352c63",
  colorBackgroundBrandSecondaryBolderPressed: "#5e4db2",
  colorBackgroundBrandSecondarySubtlest: "#f3f0ff",
  colorBackgroundBrandSecondarySubtlestHovered: "#dfd8fd",
  colorBackgroundBrandSecondarySubtlestPressed: "#b8acf6",
  colorBackgroundDangerBold: "#E3021B",
  colorBackgroundDangerBoldHovered: "#ae2a19",
  colorBackgroundDangerBoldPressed: "#601e16",
  colorBackgroundDangerDefault: "#ffedeb",
  colorBackgroundDangerHovered: "#ffd2cc",
  colorBackgroundDangerPressed: "#ff9c8f",
  colorBackgroundDiscoveryBold: "#6e5dc6",
  colorBackgroundDiscoveryBoldHovered: "#5e4db2",
  colorBackgroundDiscoveryBoldPressed: "#352c63",
  colorBackgroundDiscoveryDefault: "#f3f0ff",
  colorBackgroundDiscoveryHovered: "#dfd8fd",
  colorBackgroundDiscoveryPressed: "#b8acf6",
  colorBackgroundInformationBold: "#0c66e4",
  colorBackgroundInformationBoldHovered: "#0055cc",
  colorBackgroundInformationBoldPressed: "#09326c",
  colorBackgroundInformationDefault: "#ebf3ff",
  colorBackgroundInformationHovered: "#cce0ff",
  colorBackgroundInformationPressed: "#85b8ff",
  colorBackgroundNeutralBolder: "#44546f",
  colorBackgroundNeutralBolderHovered: "#2c3e5d",
  colorBackgroundNeutralBolderPressed: "#172b4d",
  colorBackgroundNeutralBoldest: "#1d2125",
  colorBackgroundNeutralBoldestHovered: "#2c333a",
  colorBackgroundNeutralBoldestPressed: "#454f59",
  colorBackgroundNeutralDefault: "#091e420f",
  colorBackgroundNeutralHovered: "#091e4224",
  colorBackgroundNeutralPressed: "#091e424f",
  colorBackgroundNeutralSubtle: "#ffffff00",
  colorBackgroundNeutralSubtleHovered: "#091e420f",
  colorBackgroundNeutralSubtlePressed: "#091e4224",
  colorBackgroundSuccessBold: "#1f845a",
  colorBackgroundSuccessBoldHovered: "#216e4e",
  colorBackgroundSuccessBoldPressed: "#164b35",
  colorBackgroundSuccessDefault: "#dffcf0",
  colorBackgroundSuccessHovered: "#baf3db",
  colorBackgroundSuccessPressed: "#7ee2b8",
  colorBackgroundSurfaceSurface: "#ffffff",
  colorBackgroundSurfaceSurfaceHovered: "#f1f2f4",
  colorBackgroundSurfaceSurfaceOverlay: "#ffffff",
  colorBackgroundSurfaceSurfaceOverlayHovered: "#f1f2f4",
  colorBackgroundSurfaceSurfaceOverlayPressed: "#dcdfe4",
  colorBackgroundSurfaceSurfacePressed: "#dcdfe4",
  colorBackgroundSurfaceSurfaceRaised: "#ffffff",
  colorBackgroundSurfaceSurfaceRaisedHovered: "#f1f2f4",
  colorBackgroundSurfaceSurfaceRaisedPressed: "#dcdfe4",
  colorBackgroundSurfaceSurfaceReversed: "#ffffff",
  colorBackgroundSurfaceSurfaceReversedHovered: "#f1f2f4",
  colorBackgroundSurfaceSurfaceReversedPressed: "#dcdfe4",
  colorBackgroundWarningBold: "#E50045",
  colorBackgroundWarningBoldHovered: "#ffb506",
  colorBackgroundWarningBoldPressed: "#ffac05",
  colorBackgroundWarningDefault: "#fff3d6",
  colorBackgroundWarningHovered: "#ffe399",
  colorBackgroundWarningPressed: "#ffcf51",
  colorBorderBrand: "#0c66e4",
  colorBorderBrandSecondary: "#6e5dc6",
  colorBorderDanger: "#e34935",
  colorBorderDisabled: "#091e420f",
  colorBorderDiscovery: "#8270db",
  colorBorderFocused: "#388bff",
  colorBorderHovered: "#091e424f",
  colorBorderInformation: "#1d7afc",
  colorBorderInverse: "#ffffff",
  colorBorderInvisible: "#ffffff00",
  colorBorderPrimary: "#091E4224",
  colorBorderReverse: "#1d2125",
  colorBorderSuccess: "#22a06b",
  colorBorderWarning: "#d97008",
  colorBorderWarningInverse: "#172b4d",
  colorBorderAccentBlue: "#1d7afc",
  colorBorderAccentGray: "#758195",
  colorBorderAccentGreen: "#22a06b",
  colorBorderAccentLime: "#6a9a23",
  colorBorderAccentMagenta: "#cd519d",
  colorBorderAccentOrange: "#d97008",
  colorBorderAccentPurple: "#8270db",
  colorBorderAccentRed: "#e34935",
  colorBorderAccentTeal: "#1d9aaa",
  colorBorderAccentYellow: "#ffac05",
  colorBorderBrandPrimaryBold: "#0c66e4",
  colorBorderBrandPrimaryBoldHovered: "#0055cc",
  colorBorderBrandPrimaryBoldPressed: "#09326c",
  colorBorderBrandSecondaryBold: "#6e5dc6",
  colorBorderBrandSecondaryBoldHovered: "#5e4db2",
  colorBorderBrandSecondaryBoldPressed: "#352c63",
  colorBorderDangerBold: "#ca3521",
  colorBorderDangerBoldHovered: "#ae2a19",
  colorBorderDangerBoldPressed: "#601e16",
  colorBorderDiscoveryBold: "#6e5dc6",
  colorBorderDiscoveryBoldHovered: "#5e4db2",
  colorBorderDiscoveryBoldPressed: "#352c63",
  colorBorderInformationBold: "#0c66e4",
  colorBorderInformationBoldHovered: "#0055cc",
  colorBorderInformationBoldPressed: "#09326c",
  colorBorderLimeBolder: "#5b7f24",
  colorBorderLimeBolderHovered: "#4c6b1f",
  colorBorderLimeBolderPressed: "#37471f",
  colorBorderNeutralBolder: "#758195",
  colorBorderNeutralBolderHovered: "#2c3e5d",
  colorBorderNeutralBolderPressed: "#2c3e5d",
  colorBorderSuccessBold: "#1f845a",
  colorBorderSuccessBoldHovered: "#216e4e",
  colorBorderSuccessBoldPressed: "#164b35",
  colorBorderWarningBold: "#E50045",
  colorBorderWarningBoldHovered: "#ffb506",
  colorBorderWarningBoldPressed: "#ffac05",
  colorLinkDefault: "#172B4D",
  colorLinkPressed: "#44546f",
  colorLinkVisited: "#626f86",
  colorLinkBrandedDefault: "#0c66e4",
  colorLinkBrandedPressed: "#0055cc",
  colorLinkBrandedVisited: "#5e4db2",
  colorShapeBrand: "#0c66e4",
  colorShapeBrandSecondary: "#6e5dc6",
  colorShapeDanger: "#e34935",
  colorShapeDisabled: "#091e424f",
  colorShapeDiscovery: "#8270db",
  colorShapeInformation: "#1d7afc",
  colorShapeInverse: "#ffffff",
  colorShapePrimary: "#44546f",
  colorShapeReverse: "#1d2125",
  colorShapeSuccess: "#22a06b",
  colorShapeWarning: "#d97008",
  colorShapeWarningInverse: "#172b4d",
  colorShapeAccentBlue: "#1d7afc",
  colorShapeAccentGray: "#758195",
  colorShapeAccentGreen: "#22a06b",
  colorShapeAccentLime: "#6a9a23",
  colorShapeAccentMagenta: "#cd519d",
  colorShapeAccentOrange: "#d97008",
  colorShapeAccentPurple: "#8270db",
  colorShapeAccentRed: "#e34935",
  colorShapeAccentTeal: "#1d9aaa",
  colorShapeAccentYellow: "#ffac05",
  colorShapeNeutralBolder: "#758195",
  colorShapeNeutralBolderHovered: "#2c3e5d",
  colorShapeNeutralBolderPressed: "#2c3e5d",
  colorTextBrand: "#0c66e4",
  colorTextBrandSecondary: "#6e5dc6",
  colorTextDanger: "#E3021B",
  colorTextDisabled: "#091E424F",
  colorTextDiscovery: "#5e4db2",
  colorTextInformation: "#0055cc",
  colorTextInverse: "#ffffff",
  colorTextPrimary: "#172B4D",
  colorTextSubtle: "#44546f",
  colorTextSubtlest: "#626F86",
  colorTextSuccess: "#216e4e",
  colorTextWarning: "#974f0c",
  colorTextWarningInverse: "#172b4d",
  colorTextStacked: "#ffffff",
  colorTextAccentBlue: "#0055cc",
  colorTextAccentBlueBolder: "#09326c",
  colorTextAccentGreen: "#216e4e",
  colorTextAccentGreenBolder: "#164b35",
  colorTextAccentLime: "#4c6b1f",
  colorTextAccentLimeBolder: "#37471f",
  colorTextAccentMagenta: "#943d73",
  colorTextAccentMagentaBolder: "#50253f",
  colorTextAccentMoonstone: "#206b74",
  colorTextAccentMoonstoneBolder: "#1d474c",
  colorTextAccentOrange: "#974f0c",
  colorTextAccentOrangeBolder: "#5f3811",
  colorTextAccentPurple: "#5e4db2",
  colorTextAccentPurpleBolder: "#352c63",
  colorTextAccentRed: "#ae2a19",
  colorTextAccentRedBolder: "#601e16",
  colorTextAccentYellow: "#d67d00",
  colorTextAccentYellowBolder: "#6f4100",
  colorPrimaryRed: "#e50045",
  ttbClor: "#E50045;",
};

export const buttonsColors = {
  buttonBg: "#000",
  buttonText: "#fff",
};
