/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ProjectTitle } from "consts/global";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { useCanonicalUrl } from "services/hooks/canonical";
import Loader from "components/layout/Loader";
import { ProjectsContainer } from "pages/Projects/styled";
import { Title } from "components/styled/Title";
import Pagination from "components/parts/pagination";
import { API } from "services/API/API";
import Item from "components/parts/news/Item";
import FilterMobile from "components/parts/cars/filterMobile";
import CheckFilter from "components/parts/cars/CheckFilter";
import PartsFilterModal from "components/parts/cars/PartsFilterModal";

const SpareParts = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState<{ id: string; title: string }[]>([]);
  const [projects, setProjects] = useState<{ title: string; img: string }[]>();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<{
    total: number;
    per_page: number;
  }>({
    per_page: 12,
    total: 12,
  });
  const [filters, setFilters] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const url = useCanonicalUrl();
  const meta = {
    title: `${t("spareParts")} | ${ProjectTitle}`,
    description: t("spareParts"),
    canonical: url,
  };

  const fetchData = (page: number) => {
    setProjects([]);
    const params: any = {};
    let categories = "";

    if (page) {
      params["page"] = page;
    }

    if (filters) {
      categories = filters.map((i) => `byCategorySlug[]=${i}`).join("&");
    }

    setLoader(true);
    window.scrollTo(0, 0);
    API.parts.getSpareParts(params, categories).then((res) => {
      if (res) {
        setPagination({
          per_page: res?.partsData?.pagination?.per_page,
          total: res?.partsData?.pagination?.total,
        });

        setProjects(
          res?.partsData?.data.map((item: any) => {
            return {
              title: item?.title,
              img: item["media"]?.url,
            };
          })
        );

        setCategory(
          res?.filtersData?.map((i: { slug: string; title: string }) => ({
            id: i.slug,
            title: i.title,
          }))
        );
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    fetchData(1);
    setPage(1);
  }, [lang, filters.length]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(e);
  };

  localStorage.setItem("page-category", t("spareParts"));
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      {loader && <Loader />}

      <ProjectsContainer className="container" id="age-container">
        <div className="mb-600 title">
          <Title>{t("spareParts")}</Title>
        </div>

        <FilterMobile
          openFilter={() => setOpenModal(true)}
          total={pagination.total}
        />

        <div className="row">
          <div className="col-3 col-lg-3 hide-lgtablet">
            {!!category.length && (
              <div className="parts-filter">{t("filter")}</div>
            )}
            <CheckFilter
              data={category}
              filters={filters}
              setFilters={(data) => setFilters(data)}
              showCount={10}
            />
          </div>

          <div className="col-9 col-lg-9 col-tablet-12">
            <div className="row">
              {projects?.map((item, index) => (
                <div
                  className="col-4 col-lg-4 col-md-4 col-tablet-6 col-xs-12"
                  key={`news-${index}`}
                >
                  <Item title={item.title} url={item.img} />
                </div>
              ))}
            </div>

            {pagination.total > pagination.per_page && (
              <Pagination
                onChange={(e) => onPageChange(e)}
                page={page}
                perPage={pagination.per_page}
                totalPage={pagination.total}
              />
            )}
          </div>
        </div>
      </ProjectsContainer>

      <PartsFilterModal
        open={openModal}
        close={() => setOpenModal(false)}
        filters={filters}
        setFilters={setFilters}
        category={category}
      />
    </DocumentMeta>
  );
};

export default SpareParts;
