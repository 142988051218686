/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { ReactComponent as CloseIcon } from "assets/icons/menuClose.svg";
import { ReactComponent as MenuList } from "assets/icons/List.svg";
import { ReactComponent as CaretLeft } from "assets/icons/CaretLeftMenu.svg";
import { Button } from "components/styled/Button";
import { MobileMenuContainer } from "./styled";
import { ICategories } from "types/project";
import Languages from "./languages";
import { navs } from "./consts";
import { API } from "services/API/API";
import { IMenu } from "components/layout/Header";
import RequestServiceModal from "../modal/RequestServiceModal";
import MenuItem from "./menuItem";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  open: boolean;
  categories?: ICategories[];
  close: () => void;
}

const MobileMenu: FC<IProps> = ({ open, categories, close }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [menu, setMenu] = useState<IMenu[]>([]);
  // const [activeCategory, setAvtiveCategory] = useState<{
  //   name: string;
  //   id: string;
  // } | null>();
  const [activeCategories, setActiveCategories] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const [showBrand, setShowBrand] = useState<{
    name: string;
    id: number;
  } | null>();
  const [subcategories, setSubCategories] = useState<{
    parent: string;
    data: ICategories[];
  } | null>(null);

  const products =
    location.pathname === "/products" || location.pathname === "/en/products";

  // useEffect(() => {
  //   if (id) {
  //     setAvtiveCategory((state) => ({
  //       name: state?.name || "",
  //       id: id || "",
  //     }));
  //   }
  // }, [id]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("fixed-body");
    } else {
      document.body.style.overflow = "unset";
      document.body.classList.remove("fixed-body");
    }

    return () => {
      document.body.classList.remove("fixed-body");
    };
  }, [open]);

  useEffect(() => {
    API.globals.getMenuData().then((res) => {
      setMenu(res.data);
    });
  }, [i18n.language]);

  const handleClose = () => {
    setShowBrand(null);
    // setAvtiveCategory(null);
    setActiveCategories(false);
    setSubCategories(null);
    close();
  };

  const handleItemClose = () => {
    if (subcategories) {
      setSubCategories(null);
    } else {
      // setAvtiveCategory(null);
      setActiveCategories(false);
      setShowBrand(null);
    }
  };

  return (
    <MobileMenuContainer open={open}>
      <div className="display-flex align-items-center justify-center pr-200 pl-200 header-menu">
        <div className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </div>

        <h2>{t("menu")}</h2>
      </div>

      <div className="menu-body">
        <div className="content">
          {!showBrand && !activeCategories && (
            <Button
              size="lg"
              variant="base"
              className={`categories-button ${activeCategories ? "active" : ""}`}
              onClick={() => setActiveCategories((state) => !state)}
            >
              <MenuList />
              {t("categories")}
            </Button>
          )}

          {(showBrand || activeCategories) && (
            <Button
              size="lg"
              variant="base"
              className="subcategories-button"
              onClick={() => handleItemClose()}
            >
              <CaretLeft />

              {showBrand
                ? showBrand.name
                : subcategories
                  ? subcategories.parent
                  : activeCategories
                    ? t("categories")
                    : ""}
            </Button>
          )}

          <div className="align-items-flex-start display-flex flex-column gap-100 pt-150 pb-300 header-menu-container">
            {showBrand ? (
              <>
                {!!menu.find((i) => i.id === showBrand.id)?.productBrands
                  ?.length &&
                  menu
                    .find((i) => i.id === showBrand?.id)
                    ?.productBrands.map((item) => (
                      <Link
                        key={item.id}
                        to={
                          currentLangUrl() +
                          `/brand/${item.slug}?byCategorySlug=${menu.find((i) => i.id === showBrand.id)?.slug}`
                        }
                        className={`menu-item ${item.slug === location.pathname.split("/")[2] ? "active" : ""}`}
                        onClick={() => {
                          close();
                          setShowBrand(null);
                        }}
                      >
                        {item.title}
                      </Link>
                    ))}
              </>
            ) : activeCategories ? (
              <>
                {!!subcategories ? (
                  <>
                    {subcategories.data.map((i) => (
                      <MenuItem
                        key={i.id}
                        title={i.title}
                        onClick={() => {
                          if (subcategories.parent === t("trailers")) {
                            navigate(`${currentLangUrl()}/trailers/${i.slug}`);
                          } else {
                            navigate(`${currentLangUrl()}/products/${i.slug}`);
                          }

                          handleClose();
                          close();
                        }}
                        active={products && id === i.slug}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {categories?.length &&
                      categories.map((i) => (
                        <MenuItem
                          key={i.id}
                          title={i.title}
                          arrow
                          onClick={() => {
                            setSubCategories({
                              parent: i.title,
                              data: i?.children || [],
                            });
                          }}
                        />
                      ))}

                    <MenuItem
                      title={t("trailers")}
                      arrow
                      onClick={() => {
                        setSubCategories({
                          parent: t("trailers"),
                          data: [
                            {
                              id: 1,
                              title: t("trailers"),
                              slug: "trailerstandart",
                            },
                            {
                              id: 2,
                              title: t("self-trailer"),
                              slug: "self-trailer",
                            },
                          ],
                        });
                      }}
                    />

                    <MenuItem
                      title={t("specializedCars")}
                      onClick={() => {
                        navigate(currentLangUrl() + `/specialized-cars`);
                        handleClose();
                      }}
                    />

                    <MenuItem
                      title={t("spareParts")}
                      onClick={() => {
                        navigate(currentLangUrl() + `/spare-parts`);
                        handleClose();
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {menu?.length &&
                  menu.map((i) => (
                    <MenuItem
                      key={i.id}
                      title={i.title}
                      arrow
                      onClick={() => setShowBrand({ name: i.title, id: i.id })}
                    />
                  ))}

                <div className="devider" />

                {navs.map((i) => {
                  if (i.link === "reserveService") {
                    return (
                      <div
                        key={i.title}
                        className="menu-item"
                        onClick={() => {
                          setOpenReservation(true);
                          close();
                        }}
                      >
                        {t(i.title)}
                      </div>
                    );
                  } else {
                    return (
                      <Link
                        key={i.title}
                        to={i.link}
                        className={`menu-item ${i.title === "leasing" ? "leasing" : ""} ${i.link === location.pathname ? "active" : ""}`}
                        onClick={() => close()}
                      >
                        {t(i.title)}
                      </Link>
                    );
                  }
                })}
              </>
            )}
          </div>
        </div>

        {!activeCategories && (
          <Languages setLang={(lang) => changeLanguage(lang)} center />
        )}
      </div>

      <RequestServiceModal
        open={openReservation}
        close={() => setOpenReservation(false)}
      />
    </MobileMenuContainer>
  );
};

export default MobileMenu;
