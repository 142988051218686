/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Title } from "components/styled/Title";
import { ProjectsContainer } from "./styled";
import CarFilter from "components/parts/cars/filter";
import ListItem, { IListItem } from "components/parts/news";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { ICategory, IPagination } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { FilterCotainer } from "components/parts/cars/styled";
import FilterMobile from "components/parts/cars/filterMobile";
import FilterModal from "components/parts/cars/FilterModal";
import Pagination from "components/parts/pagination";
import Loader from "components/layout/Loader";

const findElementById = (
  array: ICategory[],
  slug: string
): ICategory | null => {
  for (const element of array) {
    if (element.slug === slug) {
      return element;
    }
    if (element.children) {
      const result: ICategory | null = findElementById(element.children, slug);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const Projects = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const used = location.pathname === "/used";
  const [type, setType] = useState("");
  const [projects, setProducts] = useState<IListItem[]>([]);
  const [category, setCategory] = useState<ICategory[]>([]);
  const { id, brandId } = useParams();
  const [filters, setFilters] = useState<{
    models: string[];
    brands: string[];
  }>({
    models: [],
    brands: brandId ? [brandId] : [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<IPagination>({
    current_page: 1,
    from: 0,
    last_page: 1,
    path: "",
    per_page: 9,
    to: 0,
    total: 9,
  });
  const [filtersData, setFiltersData] = useState({
    models: [],
    brands: [],
  });
  const [loader, setLoader] = useState(false);

  const fetchData = (page: number) => {
    setProducts([]);
    const params: any = {};
    let models = "";
    let brands = "";

    if (id) {
      params["byCategorySlug"] = id;
    }

    if (page) {
      params["page"] = page;
    }

    if (filters.models.length) {
      models = filters.models.map((i) => `byModelId[]=${i}`).join("&");
    }

    if (filters.brands.length) {
      brands = filters.brands.map((i) => `byBrandSlug[]=${i}`).join("&");
    }

    setLoader(true);
    window.scrollTo(0, 0);
    API.projects.getProjects(params, models, brands).then((res) => {
      if (res) {
        setPagination({
          current_page: res?.pagination.current_page,
          from: res?.pagination?.from,
          last_page: res?.pagination?.last_page,
          path: res?.pagination?.path,
          per_page: res?.pagination?.per_page,
          to: res?.pagination?.to,
          total: res?.pagination?.total,
        });

        setProducts(
          res?.data.map((item: any) => {
            return {
              ...item,
              title: item?.title,
              description: item?.category?.title,
              url: "/product/" + item.slug,
              img: item["media"]?.thumb,
            };
          })
        );
        setLoader(false);
      }
    });

    API.projects.getProjectsFilters(params, models, brands).then((res) => {
      setCategory(res?.categories || []);
      setFiltersData({
        models: res?.models || [],
        brands:
          res?.brands.map((i: { slug: string; title: string }) => ({
            id: i.slug,
            title: i.title,
          })) || [],
      });
    });
  };

  const url = useCanonicalUrl();
  const meta = {
    title: `${type ? findElementById(category, type)?.title : t("categories")} | ${ProjectTitle}`,
    description: t("categories"),
    canonical: url,
  };

  useEffect(() => {
    fetchData(1);
    setPage(1);
    setType(id || "");
  }, [
    used,
    lang,
    id,
    filters.models.length,
    filters.brands.length,
    id,
    brandId,
  ]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(e);
  };

  localStorage.setItem("page-category", t("modelRange"));
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      {loader && <Loader />}
      <ProjectsContainer className="container">
        <div className="mb-600 title">
          <Title>
            {type ? findElementById(category, type)?.title : t("modelRange")}
          </Title>
        </div>

        <FilterMobile
          openFilter={() => setOpenModal(true)}
          total={pagination.total}
        />

        <div className="row">
          <div className="col-3 col-lg-3 hide-lgtablet">
            {!!category.length && <h3 className="mt-0">{t("filters")}</h3>}

            <CarFilter
              categories={category}
              filtersData={filtersData}
              filters={filters}
              onActive={() => {
                fetchData(1);
                setPage(1);
              }}
              setFilters={setFilters}
            />
          </div>

          <div className="col-9 col-lg-9 col-tablet-12">
            {!!projects?.length && (
              <FilterCotainer className="display-flex justify-space-between align-items-center justify-space-between hide-tablet">
                <span className="hide-mobile">
                  Showing {pagination.from}–{pagination.to} of{" "}
                  {pagination.total} results
                </span>
              </FilterCotainer>
            )}

            <div className="row">
              {projects.map((item, index) => (
                <div
                  className="col-4 col-lg-4 col-md-4 col-tablet-6 col-xs-12"
                  key={`news-${index}`}
                >
                  <ListItem projectsList {...item} description="" />
                </div>
              ))}
            </div>

            {pagination.total > pagination.per_page && (
              <Pagination
                onChange={(e) => onPageChange(e)}
                page={page}
                perPage={pagination.per_page}
                totalPage={pagination.total}
              />
            )}
          </div>
        </div>
      </ProjectsContainer>

      <FilterModal
        open={openModal}
        close={() => setOpenModal(false)}
        filters={filters}
        setFilters={setFilters}
      />
    </DocumentMeta>
  );
};

export default Projects;
