import Header from "./components/layout/Header";
import GlobalStyle from "./components/layout/GlobalStyles";
import { lightTheme } from "consts/theme";
import React, { useEffect, useState } from "react";
import Routes from "routes";
import { ThemeProvider } from "styled-components";
import "./assets/scss/index.scss";
import Footer from "./components/layout/Footer";
import ScrollToTop from "services/hooks/scroltop";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Loader from "components/layout/Loader";

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("en");
  const [loader, setLoader] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => !prevMode);
  };

  const makehreflang = () => {
    const currentUlr = location.pathname;
    const mainLink = `${window?.location?.protocol}//${window?.location?.host}`;

    if (currentUlr === "/") {
      return mainLink + "/en";
    } else if (currentUlr === "/en") {
      return mainLink;
    } else if (!currentUlr.includes("en")) {
      return mainLink + "/en" + currentUlr + location?.search;
    } else {
      return mainLink + currentUlr.replace("en/", "") + location?.search;
    }
  };

  useEffect(() => {
    const linkAlternate = document.createElement("link");
    linkAlternate.rel = "alternate";
    linkAlternate.href = makehreflang();
    linkAlternate.hreflang = i18n.language === "en" ? "ka" : "en";
    document.head.appendChild(linkAlternate);

    return () => {
      document.head.removeChild(linkAlternate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location]);

  return (
    <ThemeProvider theme={lightTheme}>
      {loader && <Loader />}
      <Header
        dark={isDarkMode}
        lang={lang}
        setLang={setLang}
        toggleTheme={toggleTheme}
        setLoader={setLoader}
      />
      <ScrollToTop />
      <GlobalStyle />
      <Routes lang={lang} />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
