/* eslint-disable @typescript-eslint/no-explicit-any */
// styled.ts
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.Neutral};
    color: ${(props) => props.theme.colorTextSubtlest};

    .color-red {
      color: ${(props) => props.theme.colorPrimaryRed};
    }
    
    a {
      color: ${(props) => props.theme.ttbClor};

      &:hover {
        color: ${(props) => props.theme.ttbClor};
      }
    }
  }
`;
export default GlobalStyle;
