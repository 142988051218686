import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IHomeBlog } from "types/home";
import { removeHtmlTags } from "helpers";
import DiscoverMore from "components/styled/DiscoverMore";
import { SubTitle } from "components/styled/SubTitle";
import BlogCard from "../blogCard";

const HomeNewsContainer = styled.div`
  padding: 80px 0;

  h2 {
    margin-bottom: 48px;
    font-size: 32px;
    line-height: 40px;
  }

  .discover-more {
    margin-top: 48px;
  }

  .row-container,
  .row-container-parent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .parent-column {
    flex: 1;
  }

  .column {
    width: calc(50% - 12px);
  }

  @media (max-width: 1919px) {
    padding: 72px 0;
  }

  @media (max-width: 1247px) {
    padding: 56px 0;

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px !important;
    }

    .discover-more {
      margin-top: 24px;
    }

    .row-container-parent {
      flex-direction: column;
    }
  }

  @media (max-width: 743px) {
    padding: 48px 0;

    h2 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px !important;
    }

    .discover-more {
      margin-top: 20px;
    }

    .column {
      width: 100%;
    }
  }

  @media (max-width: 479px) {
    padding: 40px 0;
  }
`;

interface IProps {
  blogs: IHomeBlog[];
}

const News: FC<IProps> = ({ blogs }) => {
  const { t } = useTranslation();

  const newsData = blogs.map((item) => {
    return {
      img: item.media?.url,
      url: item.slug,
      title: item.title,
      description: removeHtmlTags(item.short_description),
    };
  });

  return (
    <HomeNewsContainer>
      <SubTitle>{t("news")}</SubTitle>
      <div className="row-container-parent">
        <div className="parent-column">
          <BlogCard {...newsData[0]} />
        </div>

        <div className="parent-column">
          <div className="row-container">
            {!!newsData[1] && (
              <div className="column">
                <BlogCard isSmall {...newsData[1]} />
              </div>
            )}
            {!!newsData[2] && (
              <div className="column">
                <BlogCard isSmall {...newsData[2]} />
              </div>
            )}
            {!!newsData[3] && (
              <div className="column">
                <BlogCard isSmall {...newsData[3]} />
              </div>
            )}
            {!!newsData[4] && (
              <div className="column">
                <BlogCard isSmall {...newsData[4]} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="display-flex justify-center mt-600 descover-more">
        <DiscoverMore url="/blog" />
      </div>
    </HomeNewsContainer>
  );
};

export default News;
