import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const ProjectsContainer = styled.div`
  margin-top: 80px;

  h3 {
    color: #000;
  }

  .parts-filter {
    color: #172b4d;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  @media (max-width: 1024px) {
    .title {
      text-align: center;
    }
  }

  @media (max-width: 1247px) {
    margin-top: 32px;
  }

  @media (max-width: 743px) {
    margin-top: 20px;
  }

  @media (max-width: 479px) {
    margin-top: 24px;
  }

  @media (max-width: 480px) {
    .row {
      gap: 48px;
    }
  }

  &.specialized-cars {
    margin-top: 80px;

    @media (max-width: 1247px) {
      margin-top: 72px;
    }

    @media (max-width: 743px) {
      margin-top: 56px;
    }
  }
`;

export const SingleProjectContainer = styled.div`
  padding-bottom: 64px;
  margin-top: 80px;

  @media (max-width: 1247px) {
    margin-top: 32px;
  }

  @media (max-width: 743px) {
    margin-top: 20px;
  }

  @media (max-width: 479px) {
    margin-top: 24px;
  }

  & > div > p,
  & > p {
    margin: ${globalStyles.spaces[200]} 0;
    color: ${(props) => props.theme.colorTextPrimary};
    font-weight: 400;
  }

  .long_description h3 {
    margin-bottom: -8px;
  }

  .button {
    border-radius: var(--Input-Radius-100, 0px);
    border: 1px solid rgba(9, 30, 66, 0.14);
    background: rgba(255, 255, 255, 0);
    border-radius: 8px;
    padding: ${globalStyles.spaces[150]} ${globalStyles.spaces[200]};

    span {
      cursor: pointer;

      a:hover {
        color: ${(props) => props.theme.colorPrimaryYellow};
        text-decoration: underline;
      }
    }

    span:not(.color-red) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: ${(props) => props.theme.colorTextPrimary};
      line-height: 24px;

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }

  img {
    width: 95%;
    max-height: 560px;
    object-fit: cover;
    // border-radius: ${globalStyles.radius.cardRadiusCorner100};

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      width: 100%;
      height: auto;
    }
  }

  h1 {
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 0 0 ${globalStyles.spaces[500]} 0;
  }

  ul {
    padding-left: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colorTextPrimary};
  }

  .features {
    button {
      margin: ${globalStyles.spaces[400]} auto;
      display: flex;
      gap: ${globalStyles.spaces[100]};
      align-items: center;
      color: ${(props) => props.theme.colorPrimaryYellow};

      &:hover {
        background: transparent;
      }

      &.activeted {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    h4 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: ${(props) => props.theme.colorTextPrimary};
      margin: 0 0 ${globalStyles.spaces[200]} 0;

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        font-size: 14px;
      }
    }

    .features-list {
      overflow: hidden;
      max-height: 510px;

      &.active {
        max-height: 1500px;
      }

      div {
        padding: ${globalStyles.spaces[200]} 0;
        border-bottom: 1px solid
          var(--Color-Border-Disabled, rgba(9, 30, 66, 0.06));
        display: flex;

        span:last-child {
          color: ${(props) => props.theme.colorTextPrimary};
        }
      }

      span {
        flex: 1;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: ${(props) => props.theme.colorTextSubtlest};
      }
    }
  }

  .call-container {
    gap: 1rem;

    button {
      padding: 12px 24px;
    }

    span a {
      color: ${(props) => props.theme.colorTextPrimary};

      &:hover {
        color: ${(props) => props.theme.colorTextPrimary};
        text-decoration: underline;
      }
    }

    @media (min-width: ${globalStyles.breackPoints
        .mobile}) and (max-width: 775px) {
      flex-wrap: wrap;

      & > div {
        margin-right: 35%;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    h1 {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .call-container {
      margin: ${globalStyles.spaces[600]} 0;
      flex-direction: column;
      align-items: center;

      .button {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 0 ${globalStyles.spaces[100]} 0;
      }

      button {
        width: 100%;
      }
    }
  }

  @media (max-width: 1247px) {
    padding-bottom: 40px;
  }

  @media (max-width: 743px) {
    padding-bottom: 32px;
  }

  .description {
    margin-bottom: 32px !important;

    @media (max-width: 1247px) {
      margin-bottom: 24px !important;
    }

    @media (max-width: 1023px) {
      margin-bottom: 32px !important;
    }

    @media (max-width: 743px) {
      margin-bottom: 20px !important;
    }
  }

  .product-info {
    margin-bottom: 0 !important;
    margin-top: 128px;

    @media (max-width: 1024px) {
      margin-top: 72px;
    }

    @media (max-width: 743px) {
      margin-top: 52px;
    }
  }

  .second {
    padding: 48px 0;
  }
`;

export const SimilarProjects = styled.div`
  ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .swiper-wrapper {
    scrollbar-width: none !important;
    scrollbar-color: transparent transparent !important;
    -ms-overflow-style: none !important;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      -webkit-appearance: none !important;
      background: transparent !important;
    }
  }

  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${(props) => props.theme.colorTextPrimary};
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    h2 {
      font-size: 20px;
      line-height: 28px;
      // text-align: center;
      // margin-top: 48px;
    }

    div a h3 {
      text-align: center !important;
      margin-top: 1.25rem;
    }
  }

  .see-more {
    width: 186px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fcb026 !important;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    div > a {
      padding: 0 16px;
    }

    div h3 {
      font-size: 1rem;
    }

    .similar-mobile {
      div h3 {
        font-size: 14px;
        text-align: left;
      }

      img.superSmallImg {
        // max-height: 181px;
        margin: 0.5rem auto;
        padding: 0;
      }

      div p {
        margin: ${globalStyles.spaces[50]} 0;
        span {
          font-size: 14px;
        }
      }
    }
  }

  &.similars {
    margin-top: 80px;

    @media (max-width: 1247px) {
      margin-top: 40px;
    }

    @media (max-width: 743px) {
      margin-top: 56px;
    }
  }
`;

export const SingleProjectSimilars = styled.div`
  margin-bottom: 80px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }

  @media (max-width: 743px) {
    margin-bottom: 56px;
  }

  @media (max-width: 479px) {
    margin-bottom: 32px;
  }
`;
