import { FC } from "react";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeCover } from "types/home";
// import { useIsTouchDevice } from "helpers";

interface IProps {
  cover: IHomeCover;
}

const ParalaxContainer = styled.div<{ cover: string }>`
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  height: 550px;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: -1;

  .overlow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    opacity: 0.3;
    height: 100%;
    background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
      lightgray 50% no-repeat;
  }

  .text {
    position: absolute;
    z-index: 3;
    left: 10.5rem;
    bottom: 150px;
    color: #fff;
    max-width: 35%;

    h4 {
      font-size: ${globalStyles.fonts.large};
    }
  }

  .react-parallax-bgimage {
    background-position: top center;
    max-height: 100dvw;
  }

  .content {
    height: 550px;

    ${(props) =>
      !!props.cover &&
      `
    background-image: url("${props.cover}");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    width: 100%;
    
    `}
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    // .content {
    height: 494px;
    // }

    .text {
      max-width: 60%;
      left: 32px;
      bottom: 20px;

      h4 {
        font-size: ${globalStyles.fonts.display};
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    // .content {
    height: 366px;
    // }

    .text {
      max-width: 90%;
      bottom: 20px;

      h4 {
        font-size: ${globalStyles.fonts.display};
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    // .content {
    height: 284px;
    // }

    .text {
      max-width: 99%;
      left: 16px;
      bottom: 20px;

      h4 {
        font-size: ${globalStyles.fonts.h1};
      }
    }
  }

  img {
    height: 100vh;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: fixed;
    right: 0;
    top: 0;
    transform: perspective(0);
    width: 100%;
    z-index: -1;
    vertical-align: middle;
  }
`;

const HomeBigSection: FC<IProps> = ({ cover }) => {
  // const touchDevice = useIsTouchDevice();

  return (
    <ParalaxContainer className="paralax-container" cover={cover.media.url}>
      <div className="overlow" />

      <div className="text">
        <h4 className="mb-200">{cover.title}</h4>
        <div>{cover.description}</div>
      </div>

      {/* <div
        className="content"
        style={touchDevice ? { backgroundAttachment: "scroll" } : {}}
      /> */}

      <img src={cover.media.url} alt={cover.title || cover.media.url} />
    </ParalaxContainer>
  );
};

export default HomeBigSection;
