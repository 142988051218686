import axiosInstance from "..";

const getSpareParts = (params?: any, categories?: string) => {
  return axiosInstance
    .get(`spare_parts/${categories ? `?${categories}` : ""}`, {
      params: { ...params, per_page: 18 },
    })
    .then((res) => {
      return res.data;
    });
};

const getSpecializedCars = (page: number) => {
  return axiosInstance
    .get("special_vehicles", {
      params: { page, per_page: 16 },
    })
    .then((res) => {
      return res.data;
    });
};

const getTrailes = (params?: any, categories?: string) => {
  return axiosInstance
    .get(`trailers/${categories ? `?${categories}` : ""}`, {
      params: { ...params, per_page: 16 },
    })
    .then((res) => {
      return res.data;
    });
};

export const parts = {
  getSpareParts,
  getSpecializedCars,
  getTrailes,
};
