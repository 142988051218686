import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { currentLangUrl } from "services/hooks/canonical";
interface IProps {
  url: string;
}

const DiscoverMore: FC<IProps> = ({ url }) => {
  const { t } = useTranslation();

  return (
    <Link to={currentLangUrl() + url}>
      <Button variant="base" size="lg" style={{ fontSize: 16 }}>
        {t("discoverMore")}
      </Button>
    </Link>
  );
};

export default DiscoverMore;
