import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const HeaderTopContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  background: var(--Color-Background-Input_hovered, #f7f8f9);

  .side-menu {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f2f4;
    cursor: pointer;

    &:hover {
      background: #091e420f;
    }
  }

  .menu-item {
    font-size: 14px;
    color: ${(props) => props.theme.colorLinkDefault};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.leasing {
      color: #d67d00;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderContainer = styled.header`
  background-color: transparent;
  position: relative;
  border-bottom: 1px solid rgba(9, 30, 66, 0.14);

  .icon-container {
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    cursor: pointer;
  }

  .show-md {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  @media (max-width: 1919px) {
    .container {
      width: 100%;
      padding: 0 24px;
      max-width: 100dvw;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    .container {
      padding: 0 16px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .container {
      padding: 0 12px;
    }
  }

  &.fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(0, 0, 0, 0) 184.94%
    );
  }

  &.dark:not(.fixed-header) {
    background-color: ${(props) => props.theme.colorBackgroundNeutralBoldest};
  }
`;

export const HeaderSearchContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  position: relative;

  .logo , .logo svg {
    height: 48px;
    width: 125.357px;

    @media (max-width: 1024px) {
      height: 40px;
      width: 103px;
    }

    @media (max-width: 743px) {
      height: 32px;
      width: 84px;
    }
  }

  .categories-button {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &.active {
      background-color: ${(props) => props.theme.ttbClor};

      color: #fff;

      path {
        fill: #fff;
      }
    }

    @media (max-width: 1248px) {
      margin-right: 32px;
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      display: flex;
    }
  }

  .phone-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    padding: 0;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .header-phone {
    span {
     color: #626f86;
    }

    @media (min-width: ${globalStyles.breackPoints.lgTablet}) {
      min-width: 150px;
    }

    .phone {
       color: ${(props) => props.theme.colorLinkDefault};

      &:hover {
        text-decoration: underline;
      }
    }s
  }

  .menu-list-icon {
    display: none;
    padding: 12px;
    align-items: center;
    cursor: pointer;
    height: 48px;
    width: 48px;
  }

  .search {
    position: relative;
    min-width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: none;

    svg {
      cursor: pointer;
    }

    &-container {
      position: absolute;
      width: 0px;
      overflow: hidden;
      top: -12px;
      right: 0;
      display: flex;

      svg {
        width: 24px;
        height: 24px;
      }

      & > span {
        height: 54px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 5px;
      }

      &.active {
        width: 220px;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      height: 48px;
      width: 48px;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  .desc-search {
    display: flex;
    align-items: center;
    height: 56px;
    width: 400px;
    background: #f7f8f9;
    border: 1px solid #f7f8f9;
    border-radius: 40px;
    padding: 4px;
    overflow: hidden;

    form,
    input {
      height: 100%;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      font-size: 16px;
    }

    form {
      padding: 0 8px 0 16px;
    }

    .icon {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 16px 0 8px;
      cursor: pointer;
    }

    &:hover {
      border: 1px solid #091e424f;
    }

    &:focus-within {
      border: 2px solid #e50045;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .burger-menu {
    cursor: pointer;
    position: relative;
    z-index: 7;
    width: 24px;
    height: 19px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #44546f;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    &.dark span {
      background: #fff;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: 8px;
    }

    span:nth-child(3) {
      top: 16px;
    }

    &.open span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      top: 9px;
      background: #44546f;
    }

    &.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
      background: #44546f;
    }

    &.open span:nth-child(3) {
      top: 9px;
      background: #44546f;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }

  @media (max-width: 1248px) {
    .phone-container {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .menu-list-icon {
      display: block;
    }
  }

`;

export const HeaderSearchInputContainer = styled.div`
  width: 30%;
`;

interface IProps {
  open: boolean;
}

export const MobileMenuContainer = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? "0px" : "-120%")};
  width: 100dvw;
  height: 100dvh;
  z-index: 12;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  div.header-menu {
    position: relative;
    min-height: 72px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${(props) => props.theme.colorBorderDisabled};

    h2 {
      color: ${(props) => props.theme.colorLinkDefault};
      margin: 0;
      width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      text-align: center;
    }

    .close-icon {
      position: absolute;
      left: 20px;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .menu-body {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 74px);
    padding: 32px 24px;
    overflow: scroll;
    scrollbar-width: none;

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .categories-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin-bottom: 24px;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.64px;
        color: #172b4d;

        @media (min-width: ${globalStyles.breackPoints.tablet}) {
          display: none;
        }
      }

      .subcategories-button {
        display: flex;
        align-items: center;
        text-align: left;
        gap: 12px;
        color: #44546f;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .menu-item {
      font-size: 16px;
      color: ${(props) => props.theme.colorLinkDefault};
      cursor: pointer;
      display: flex;
      align-items: center;
      min-height: 48px;
      width: 100%;
      margin-bottom: 8px;
      padding: 0.25rem 12px 0.25rem 16px;
      border-radius: 4px;
      justify-content: space-between;

      &.leasing {
        color: #d67d00;
        margin-bottom: 0;
      }

      &.active {
        background: #f7f8f9;
        font-weight: bold;
      }
    }
  }

  .devider {
    height: 1px;
    width: 100%;
    background: rgba(9, 30, 66, 0.06);
    margin: 24px 0;
  }
`;

export const MenuDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 ${globalStyles.radius.cardRadiusCorner100}
    ${globalStyles.radius.cardRadiusCorner100};
  background: ${(props) => props.theme.colorBackgroundSurfaceSurfaceHovered};
  box-shadow: 0px 8px 16px -8px rgba(23, 43, 77, 0.2);
  padding: ${globalStyles.spaces[100]} ${globalStyles.spaces[150]}
    ${globalStyles.spaces[300]} ${globalStyles.spaces[150]};
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 5;

  li {
    min-width: 250px;
    display: block;
    padding: 0 ${globalStyles.spaces[150]};
    text-align: left;
    border-radius: ${globalStyles.radius.cardRadiusCorner50};
    background: ${(props) => props.theme.colorBackgroundSurfaceSurfaceHovered};

    &:hover {
      background: #fff;
    }

    a {
      display: block;
      height: 60px;
      line-height: 60px;
      color: ${(props) => props.theme.colorTextPrimary};
    }
  }
`;

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--Color-Border-Primary, rgba(9, 30, 66, 0.14));

  button.position-relative {
    border-radius: 0;

    svg {
      position: relative;
      top: 3px;
      left: 8px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: ${(props) =>
        props.theme.colorBackgroundSurfaceSurfaceReversedHovered};
      color: ${(props) => props.theme.colorTextPrimary};

      svg {
        transform: rotate(-180deg);

        path {
          fill: #44546f;
        }
      }

      div {
        opacity: 1;
        pointer-events: all;
        max-height: 300px;
      }
    }
  }
`;

export const BrandHeader = styled.div<{
  home: boolean;
  transparent: boolean;
  open: boolean;
}>`
  position: ${(props) => (props.home ? "absolute" : "relative")};
  left: 0;
  top: 100%;
  z-index: 2;
  background: ${(props) => (props.transparent ? "transparent" : "#1d2125")};
  width: 100dvw;
  height: ${(props) => (props.open ? "calc(100dvh - 80px)" : "auto")};

  .brand-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    height: 88px;
    border-bottom: ${(props) => (props.open ? "1px solid #34383C" : "none")};

    &.hino {
      height: 110px;

      .brand-logo {
        height: 70px;
      }
    }
  }

  .brand-logo {
    height: 48px;
  }

  .list {
    display: flex;
    align-items: center;

    .list-item {
      font-size: 20px;
      color: #fff;
      padding: 12px 32px;
      cursor: pointer;
    }

    @media (max-width: 1025px) {
      display: none;
    }
  }

  .brand-menu-body {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    overflow: scroll;

    .list-item {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 4px 16px;
      color: #fff;
      font-weight: bold;
      border-radius: 8px;

      &.active {
        background: #34383c;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .brand-container {
      height: 72px;

      &.hino {
        height: 94px;
      }
    }
  }
`;

export const CategoriesContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 80px);
  height: 328px;
  z-index: 6;
  border-radius: 16px;
  background: #fff;
  display: none;
  overflow: hidden;
  width: unset;

  .categories {
    flex: 1;
    overflow: scroll;
    scrollbar-width: none;
    min-width: 506px;

    div {
      padding: 12px 24px;
      color: ${(props) => props.theme.colorLinkDefault};
      letter-spacing: 0.64px;
      cursor: pointer;
      border-radius: 8px;

      &:hover,
      &.active {
        background: #091e420f;
      }
    }
  }

  .sub-categories {
    flex: 1;
    color: ${(props) => props.theme.colorLinkDefault};
    min-width: 379px;

    .column,
    .col-6 {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      div {
        cursor: pointer;
      }
    }

    .column-item {
      display: flex;
      flex-dirsction: column;
      flex-wrap: wrap;
      max-height: 280px;

      div {
        width: 50%;
      }
    }
  }

  @media (min-width: ${globalStyles.breackPoints.tablet}) {
    display: flex;
  }

  @media (max-width: 1024px) {
    top: calc(100% + 16px);
  }

  @media (max-width: 950px) {
    width: 100%;

    &.hide {
      width: unset;
    }

    .categories,
    .sub-categories {
      min-width: unset;
    }
  }
`;

export const GlobalMenuContainer = styled.div`
  position: fixed;
  left: calc(calc(100dvw - 1590px) / 2);
  right: auto;
  top: 48px;
  z-index: 999;
  max-width: 444px;
  overflow: hidden;
  transition: height 0.3s ease-in-out 0s;
  display: none;

  @media (max-width: 1919px) {
    left: 24px;
  }

  .menu-header {
    display: none;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(9, 30, 66, 0.14);
    height: 72px;
    position: relative;

    h2 {
      font-size: 20px;
      color: #172b4d;
      font-weight: bold;
    }

    .close-icon {
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .back-icon {
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .content {
    height: 100%;
    max-height: 80dvh;
    overflow: scroll;
    scrollbar-width: none;
  }

  @media (max-width: 1024px) {
    left: auto;
    right: 24px;
    top: 60px;
  }

  &.open {
    display: block;
  }

  .menu-container {
    height: 100%;
    width: 100%;
    max-height: 85dvh;
    background: #fff;
    padding: 24px;
    transition: height 0.3s ease-in-out 0s;
    border-radius: 8px;

    .baner {
      display: flex;
      justify-content: space-between;
      background: #34383c;
      border-radius: 4px;
      width: 100%;
      height: 132px;
      // padding: 17px 0 17px 24px;

      .baner-text {
        margin-top: 7px;

        svg {
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          line-height: 22px;
          padding: 0;
          margin: 0;
          color: #fff;
          max-width: 155px;
        }
      }

      & > svg {
        @media (max-width: ${globalStyles.breackPoints.tablet}) {
          max-width: 40%;
        }
      }
    }
    border: 4px solid #e7e7e8;
  }

  .header {
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e7e7e8;

    svg {
      width: 88px;
    }
  }

  .line {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 88px;
    height: 2px;
    background: #fdb813;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 13px;
      border-top: 5px solid #fdb813;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    flex-wrap: wrap;
    padding: 8px 0;
    margin-bottom: 4px;

    div {
      color: #0b0c0e;
      font-weight: bold;
    }

    a {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      color: #525659;

      &:hover {
        color: #525659;
        text-decoration: underline;
      }
    }
  }

  .items-row {
    display: flex;
    flex-wrap: wrap;

    .cols {
      min-height: 112px;
      width: calc(100% / 3);
      text-align: center;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    color: #0b0c0e;
    gap: 8px;
    padding: 16px 0;

    .item-img-container {
      height: 48px;
      width: 48px;
      background: #f3f3f3;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 30px; // to do
      }
    }

    &.-title {
      text-transform: uppercase;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100dvw;
    max-width: unset;

    .menu-container {
      padding: 0;
      border-radius: 0;
      border: none;
      max-height: unset;
    }

    .content {
      padding: 16px;
      padding-bottom: 70px;
      max-height: unset;
    }

    &.open {
      height: 100dvh;
      max-height: unset;
    }

    .menu-header {
      display: flex;
    }
  }

  @media (max-width: 320px) {
    .title-wrapper {
      flex-direction: column;
      align-items: start;
    }

    .items-row .cols {
      width: calc(100% / 2);
    }
  }
`;

export const DesktopMenuConteint = styled.div`
  height: 64px;
  background: #f7f8f9;

  .brands-wrapper {
    height: 100%;
    margin-left: -3rem;

    @media (max-width: 1247px) {
      margin-left: -32px;
    }
  }

  .brands-container {
    position: relative;
    height: 100%;
    width: calc(100% + 3rem);

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .brands-categories {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: ${(props) => props.theme.colorTextPrimary};
    font-size: 20px;
    cursor: pointer;
    font-feature-settings: "case" on;
    letter-spacing: 0.32px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &.hover {
      background: #1d2125;
      color: #fff;
    }

    @media (max-width: 1247px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .brands {
    position: absolute;
    top: 100%;
    left: 0;
    background: #1d2125;
    color: #c7d1db;
    z-index: 3;
    display: flex;
    padding: 16px;
    border-radius: 0 0 16px 16px;
    width: 85dvw;
    max-width: 1618px;

    button {
      font-size: 20px;
    }

    @media (max-width: 1919px) {
      width: 100dvw;
      left: 24px;
      max-width: unset;
    }

    @media (max-width: 1247px) {
      width: calc(100dvw - 16px);
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
