import styled from "styled-components";

export const SpecializedCarsContainer = styled.div`
  margin-bottom: 80px;

  .cars-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .cars-page-title {
    margin-bottom: 48px;

    h2 {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .cars-col {
    flex: 1;
    min-width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex: 0 0 calc((100% - 264px) / 12 * 3 + 48px);
  }

  @media (max-width: 1919px) {
    .cars-col {
      flex: 0 0 calc((100% - 264px) / 12 * 4 + 72px);
    }
  }

  @media (max-width: 1247px) {
    margin-bottom: 72px;

    .cars-page-title {
      margin-bottom: 40px;

      h2 {
        font-size: 32px;
      }
    }

    .cars-col {
      flex: 0 0 calc((100% - 264px) / 12 * 6 + 120px);
    }
  }

  @media (max-width: 743px) {
    margin-bottom: 56px;

    .cars-page-title {
      margin-bottom: 24px;

      h2 {
        font-size: 24px;
      }
    }

    .cars-col {
      flex: 0 0 calc((100% - 264px) / 12 * 12 + 264px);
    }
  }
`;
