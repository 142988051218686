import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeProducts } from "types/home";
import { SubTitle } from "components/styled/SubTitle";
import { IItem } from "../list";
import CarItem from "./carItem";

interface IProps {
  title?: string;
  hidemore?: boolean;
  scroll?: boolean;
  products?: IHomeProducts[];
}

const ModelList = styled.div`
  padding: 80px 0;

  .row {
    gap: 8px;
  }

  .single-model {
    position: relative;
    height: 384px;
    width: calc(calc(100% - calc(3 * 8px)) / 4);

    .img {
      width: 100%;
      height: 384px;
      object-fit: cover;
      border-radius: 4px;
    }

    &-info {
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      color: #fff;
      z-index: 2;
      width: calc(100% - 4rem);

      h3 {
        margin: 0.25rem 0 0 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }

    @media (max-width: 1024px) {
      width: calc(calc(100% - 8px) / 2);
    }

    @media (max-width: 743px) {
      width: 100%;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lg}) {
    .single-model,
    .single-model .img {
      height: 270px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .single-model,
    .single-model .img {
      height: 361px;
    }

    .single-model-info {
      bottom: 1.5rem;
      left: 1.5rem;
      width: calc(100% - 3rem);

      h3 {
        font-size: 20px;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .single-model,
    .single-model .img {
      height: 259px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .single-model,
    .single-model .img {
      height: 330px;
    }
  }

  @media (max-width: 1023px) {
    padding: 48px 0;
  }
`;

const HomeCars: FC<IProps> = ({ products }) => {
  const { t } = useTranslation();
  const [models, setModels] = useState<IItem[]>([]);

  useEffect(() => {
    if (products && products.length > 0) {
      const arr: IItem[] = products.map((item) => {
        return {
          title: item.title,
          model: item.brand,
          price: item.price || 0,
          img: item?.media?.url,
          url: item.slug,
        };
      });

      setModels(arr);
    }
  }, [products]);

  return (
    <ModelList>
      <SubTitle className="lg-space">{t("categories")}</SubTitle>
      <div className="row">
        {models.map((item) => (
          <CarItem item={item} />
        ))}
      </div>
    </ModelList>
  );
};

export default HomeCars;
