import { globalStyles } from "consts/spaces";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import CaretDown from "assets/icons/CaretDown.svg";
import { useOnClickOutside } from "helpers";

// Define the types for your dropdown items
interface DropdownItem {
  value: string;
  label: string;
}

// Props for the Dropdown component
interface IProps {
  items: DropdownItem[];
  onSelect: (value: string) => void;
  label: string;
  value: string | number | null;
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  padding: ${globalStyles.spaces[150]} 0;
  min-width: 125px;
  border-radius: ${globalStyles.radius.inputRadius100};
  border: 1px solid ${(props) => props.theme.colorBorderPrimary};
  padding: 0 ${globalStyles.spaces[200]};
  height: 54px;

  &:hover {
    border-color: #091e424f;
  }

  &:focus-within {
    border: 2px solid #e50045;
  }
`;

const DropdownButton = styled.button`
  background-color: transparent;
  font-size: 16px;
  color: ${(props) => props.theme.colorTextSubtlest};
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    transition: all 0.3s ease-in-out;
    padding-left: ${globalStyles.spaces[100]};
    position: relative;

    &.active {
      transform: rotate(180deg);
      padding-left: 0;
      padding-right: ${globalStyles.spaces[100]};
    }
  }

  &:focus-visible {
    outline: none;
    border: none;
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  left: 0px;
  list-style: none;
  padding: 8px 12px;
  margin: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px -8px #172b4d33;
  border: 1px solid #091e4224;
  border-radius: 0.5rem;
  z-index: 5;
  max-height: 200px;
  overflow: scroll;
`;

const DropdownListItem = styled.li`
  text-align: left;
  cursor: pointer;
  font-size: ${globalStyles.fonts.p14};
  color: ${(props) => props.theme.colorTextSubtlest};
  padding: 8px 16px 8px 12px;
  border-radius: 8px;

  &:hover {
    background: #091e420f;
    color: #172b4d;
  }
`;

const Dropdown: React.FC<IProps> = ({ items, onSelect, label, value }) => {
  const node = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item: DropdownItem | number) => {
    if (typeof item === "number") {
      onSelect("");
      setIsOpen(false);
    } else {
      onSelect(item.value);
      setIsOpen(false);
    }
  };

  useOnClickOutside(node, () => setIsOpen(false));

  return (
    <DropdownContainer ref={node}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {value ? items.find((i) => i.value === value)?.label : label}
        <img className={isOpen ? "active" : ""} src={CaretDown} alt="carret" />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          <DropdownListItem onClick={() => handleItemClick(0)}>
            {label}
          </DropdownListItem>
          {items.map((item) => (
            <DropdownListItem
              key={item.value}
              onClick={() => handleItemClick(item)}
            >
              {item.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
