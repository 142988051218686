import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import {
  // IHomeBlog, IHomeFeatures
  IHomeSlider,
} from "types/home";
import { useCanonicalUrl } from "services/hooks/canonical";
import HomeSlider from "components/parts/home/slider";
import { IListItem } from "components/parts/news";
import { HomePageContainer } from "pages/Homepage/styled";
import { BrandLayout } from "./styled";
import { useQuery } from "helpers";
import { useParams } from "react-router-dom";
import HomeProjects from "components/parts/home/projects";
import HomeBigSection from "components/parts/home/bigSection";

const BrandLanding = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  let query = useQuery();
  const [data, setData] = useState({
    content_quote: "",
    content_text: "",
    content_title: "",
  });
  const [cover, setCover] = useState<string>();
  const [slider, setSlider] = useState<IHomeSlider[]>([]);
  const [products, setProducts] = useState<IListItem[]>([]);
  const url = useCanonicalUrl();
  const meta = {
    title: `${t("main")} | ${ProjectTitle}`,
    description: t("main"),
    canonical: url,
  };
  // const deviceType = useDeviceType();
  const lang = i18n.language;

  useEffect(() => {
    if (id) {
      API.brand
        .getLanding(id, query.get("byCategorySlug") || "")
        .then((res) => {
          setCover(res?.cover_image?.url);
          setSlider(res?.cover_slider);
          setProducts(
            res?.products?.map(
              (item: {
                img: string;
                media: { url: string };
                url: string;
                slug: string;
              }) => {
                item.img = item?.media?.url;
                item.url = item.slug;
                return item;
              }
            )
          );
          setData({
            content_quote: res?.content_quote,
            content_text: res?.content_text,
            content_title: res?.content_title,
          });
        });
    }
  }, [lang, id, query]);

  return (
    <DocumentMeta {...meta}>
      <HomePageContainer>
        <BrandLayout>
          {!!slider?.length && (
            <section>
              <HomeSlider slider={slider} brand={id} />
            </section>
          )}

          {!!products?.length && (
            <section
              className="pt-1000 pb-800 brand-products"
              style={{ overflow: "hidden" }}
            >
              <HomeProjects
                brand
                discoverMore
                title={
                  lang === "en"
                    ? `Catalog of ${id?.toUpperCase()}`
                    : `${id?.toUpperCase()}-ის კატალოგი`
                }
                projects={products}
              />
            </section>
          )}

          {cover && <HomeBigSection cover={{ media: { url: cover } }} />}

          {(data?.content_title || data?.content_text) && (
            <div className="grey-bg brand-info pt-1000 pb-1000">
              <div className="container">
                <h3>{data.content_title}</h3>
                <div className="description">
                  <p>{data.content_text}</p>
                  {!!data.content_quote && (
                    <p>
                      <blockquote>{data.content_quote}</blockquote>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </BrandLayout>
      </HomePageContainer>
    </DocumentMeta>
  );
};

export default BrandLanding;
