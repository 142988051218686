import Loader from "components/layout/Loader";
import Item from "components/parts/news/Item";
import Pagination from "components/parts/pagination";
import { Title } from "components/styled/Title";
import { ProjectTitle } from "consts/global";
import { ProjectsContainer } from "pages/Projects/styled";
import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API } from "services/API/API";
import { useCanonicalUrl } from "services/hooks/canonical";
import { SpecializedCarsContainer } from "./styled";

const SpecializedCars = ({ type }: { type?: string }) => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<{
    total: number;
    per_page: number;
  }>({
    per_page: 12,
    total: 12,
  });
  const [data, setData] = useState<{ title: string; img: string }[]>([]);
  const [pageType, setPageType] = useState("specializedCars");

  const url = useCanonicalUrl();
  const meta = {
    title: `${t("specializedCars")} | ${ProjectTitle}`,
    description: t("specializedCars"),
    canonical: url,
  };
  const { slug } = useParams();

  const fetchData = (page: number) => {
    setLoader(true);

    if (type) {
      API.parts
        .getTrailes(
          page,
          !slug
            ? "byCategorySlug[]=trailerstandart&byCategorySlug[]=self-trailer"
            : `byCategorySlug[]=${slug}`
        )
        .then((res) => {
          setPagination({
            per_page: res?.partsData?.pagination?.per_page,
            total: res?.partsData?.pagination?.total,
          });

          setData(
            res?.partsData?.data.map((item: any) => {
              return {
                title: item?.title,
                img: item["media"]?.url,
              };
            })
          );

          setLoader(false);
        });
    } else {
      API.parts.getSpecializedCars(page).then((res) => {
        setPagination({
          per_page: res?.partsData?.pagination?.per_page,
          total: res?.partsData?.pagination?.total,
        });

        setData(
          res?.partsData?.data.map((item: any) => {
            return {
              title: item?.title,
              img: item["media"]?.url,
            };
          })
        );

        setLoader(false);
      });
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [i18n.language, type, slug]);

  const onPageChange = (e: number) => {
    window?.scrollTo(0, 0);
    setPage(e);
    fetchData(e);
  };

  useEffect(() => {
    setPageType(slug || type || "specializedCars");
  }, [slug, type]);

  localStorage.setItem("page-category", t(pageType));
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      {loader && <Loader />}

      <SpecializedCarsContainer>
        <ProjectsContainer className="container specialized-cars">
          <div className="cars-page-title">
            <Title className="text-center">{t(pageType)}</Title>
          </div>

          <div className="cars-row">
            {data?.map((item, index) => (
              <div className="cars-col" key={`${pageType}-${index}`}>
                <Item title={item.title} url={item.img} />
              </div>
            ))}
          </div>

          {pagination.total > pagination.per_page && (
            <Pagination
              onChange={(e) => onPageChange(e)}
              page={page}
              perPage={pagination.per_page}
              totalPage={pagination.total}
            />
          )}
        </ProjectsContainer>
      </SpecializedCarsContainer>
    </DocumentMeta>
  );
};

export default SpecializedCars;
