import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(9, 30, 66, 0.14);
  border-radius: 16px;

  .img-container {
    padding: 1rem;

    img {
      width: 100%;
    }
  }

  .title-container {
    padding: 0.5rem 2rem 1.5rem;
  }

  .title {
    width: 100%;
    text-align: center;
    color: #172b4d;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`;

const Item = ({ title, url }: { title: string; url: string }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const updateWidth = () => {
          if (imgRef.current) {
            setImgWidth(imgRef?.current?.offsetWidth);
          }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
          window.removeEventListener("resize", updateWidth);
        };
      }, 0);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgRef?.current?.offsetWidth]);

  return (
    <Container>
      <div className="img-container">
        <img
          ref={imgRef}
          src={url}
          alt={title}
          style={{ height: imgWidth / 1.8 }}
        />
      </div>

      <div className="title-container">
        <div className="title" title={title}>
          {title}
        </div>
      </div>
    </Container>
  );
};

export default Item;
