import { FC, useState } from "react";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeSlider } from "types/home";
import { Slide } from "components/styled/Slide";
import { ReactComponent as CarretRightSlider } from "assets/icons/CaretRightSlider.svg";
import { Button } from "components/styled/Button";
import { removeHtmlTags } from "helpers";
import { useTranslation } from "react-i18next";
import Request from "../request";

import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const CarouselContainer = styled.div`
  position: relative;

  .arrows {
    position: absolute;
    z-index: 2;
    bottom: 45%;
    left: 50%;
    transform: translateX(-50%);
    width: 100dvw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;

    .arrow {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff;
      border-radius: 4px;

      svg {
        width: 30px;
      }

      &.swiper-button-disabled {
        visibility: hidden;
      }
    }

    .swiper-prev {
      transform: rotate(-180deg);
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }
  }

  .swiper-pagination {
    display: none;
    bottom: 62px;

    .swiper-pagination-bullet {
      height: 8px;
      width: 8px;
      background: #fff;
      margin: 0 2px;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        width: 24px;
        border-radius: 8px;
      }
    }

    @media (max-width: 1247px) {
      display: block;
    }

    @media (max-width: 1023px) {
      bottom: 56px;
    }

    @media (max-width: 743px) {
      bottom: 12px;
    }
  }
`;

interface IProps {
  slider: IHomeSlider[];
  brand?: string;
}

const HomeSlider: FC<IProps> = ({ slider, brand }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState(0);

  return (
    <CarouselContainer>
      <Swiper
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
          disabledClass: "swiper-button-disabled",
        }}
        className="main-slider"
        pagination={true}
      >
        {slider.map((item) => (
          <SwiperSlide key={item.id} className="main-slider">
            <Slide>
              <img src={item?.media?.url} alt={item.title} />
              <div>
                <div className="container">
                  {brand && <h1>{brand}</h1>}
                  <h2>{item.title}</h2>
                  <p className="hide-mobile">
                    {removeHtmlTags(item.description)}
                  </p>
                  <div className="display-flex gap-200 buttons">
                    {item?.link && !brand && (
                      <Button
                        link={item?.link}
                        size="lg"
                        style={
                          brand ? { background: "#E50045", color: "#fff" } : {}
                        }
                      >
                        {t("getStarted")}
                      </Button>
                    )}

                    {/* {!!brand && (
                      <Button
                        size="lg"
                        variant="base-white"
                        onClick={() => setRequest(1)}
                        style={
                          !item?.link
                            ? { background: "#E50045", color: "#fff" }
                            : {}
                        }
                        className="call-button"
                      >
                        {t("requestACall")}
                      </Button>
                    )}

                    <Button
                      link={item.link}
                      size="lg"
                      className="light-primary-button"
                    >
                      {t("getStarted")}
                    </Button> */}
                  </div>
                </div>
              </div>
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="arrows">
        <div className="arrow swiper-prev">
          <CarretRightSlider />
        </div>

        <div className="arrow swiper-next">
          <CarretRightSlider />
        </div>
      </div>

      <Request open={request} close={() => setRequest(0)} brand />
    </CarouselContainer>
  );
};

export default HomeSlider;
